* {
  box-sizing: border-box;
}

body {
  background-color: #fafafa !important;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background: linear-gradient(to right, #4076D9, #28CA75);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* Scrollbar */
::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 6px;
}

/* Change the color when the thumb is hovered */
::-webkit-scrollbar-thumb:hover {
  background: #aaa;
}

::-webkit-scrollbar-corner {
  background: #f1f1f1;
}

.bg-f0 {
  background-color: #f0f0f0;
}

.bg-table-head {
  background-color: #e8eefa;
}

.header-style {
  height: 70px;
  background: linear-gradient(to right, #4076D9, #28CA75);
}

.sidebar-style {
  height: 100vh !important;
  background: #f6f6f6;
  border-right: 1px solid #ccc;
}

.sidebar {
  height: calc(100vh - 80px) !important;
}

.sidebar-width-preview {
  width: 250px !important;
}

/* Configure page */
.font-14 {
  font-size: 14px !important;
}

.font-16 {
  font-size: 16px !important;
}

.text-color-a {
  color: #aaa;
}

.text-color-7 {
  color: #777;
}

.btn-blue{
  background-color: #4076D9 !important;
}
.bg-green {
  background-color: #c0ecd6 !important;
}

.bg-yellow {
  background-color: #f9fcce !important;
}

.bg-red {
  background-color: #fccece !important;
}

.font-500 {
  font-weight: 500;
}

.form-control {
  font-size: 16px !important;
  min-height: 40px !important;
  /* line-height: 45px !important; */
}

.form-control::placeholder {
  color: #aaa !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.save-custom {
  width: 150px;
  height: 40px;
  background: #4076D9;
  border: none;
  border-radius: 4px;
  color: #fff;
}

.create-new-icon {
  width: 50px;
  height: 50px;
}

.set-table {
  margin-right: 20px;
}

.create-gpt {
  width: 70%;
}

.row-style:hover {
  background-color: #f4f4f4;
}

.listing-logo {
  width: 40px;
  height: 40px;
}

.uploaded-files-format {
  width: 40px;
}

.input-style {
  width: 85%;
  min-height: 60px;
  border-radius: 6px;
  border: 1px solid #777;
}

.send-question {
  width: 48px;
  height: 48px;
  background: linear-gradient(to right, #4076D9, #28CA75);
}

.preview-inputarea:focus {
  outline: none;
}

.chat-main {
  height: calc(100vh - 200px);
}

.header-user-image {
  width: 50px;
  height: 50px;
}
/* .header-user-image-bg {
  width: 50px;
  height: 50px;
} */

.new-chat {
  background: #4076D9;
  height: 40px;
}

.attachment-type {
  width: 40px;
  height: 40px;
}

.close-attachment {
  width: 20px;
  height: 20px;
  background-color: #f0f0f0;
  top: 0px;
  right: 22px;
}

.blur {
  filter: blur(1px);
}

.attachment-spinner {
  width: 20px !important;
  height: 20px !important;
}

.loader-overlay {
  margin-top: 10px;
}

.feedback-section {
  background-color: #f0f0f0;
  width: 100px;
}

.vr {
  border-left: 2px solid #000 solid #777;
  height: 100%;
}

.sender-chat {
  width: fit-content;
}

.comment-box {
  font-size: 14px;
  height: 30px;
  width: 350px;
  line-height: 45px;
}

.comment-box:focus {
  outline: none;
}

.send-comment {
  width: 24px;
  height: 24px;
  background: #454545;
}

.comment-box-main {
  border: 1px solid #d1d1d1;
  height: 30px;
  width: 350px;
  line-height: 45px;
}

.history-content:hover {
  background: #4076D9;
  color: #fff;
}

.preview-dropdown {
  font-size: 12px;
  font-weight: bold;
}

.info-icon {
  width: 14px;
  height: 14px;
}

.add-new-wrapper {
  position: relative;
  width: 120px;
  height: 120px;
}

.defaultprofile {
  z-index: 1;
}

.default-change-image {
  position: absolute;
  top: 90px;
  left: 85px;
  width: 30px;
  height: 30px;
  z-index: 2;

}

.model-temp {
  font-size: 12px !important;
  height: 32px !important;
}

.preview-select-width {
  width: 25%;
}

.deepkak-ke-project-ki-width {
  width: 90%;
}
.sidebar-edit-session{
  font-size: 14px !important;
  min-height: 35px !important;
}

@media (max-width: 767px) {
  .preview-select-width {
    width: 50%;
  }

  .deepkak-ke-project-ki-width {
    width: 100%;
  }

  .listing-logo {
    width: 30px;
    height: 30px;
  }

  .attachment-expand {
    width: 320px !important;
    /* height: 140px !important; */
    left: 12% !important;
    bottom: 7% !important;
  }
}

.attachment-expand {
  width: 420px;
  /* height: 135px; */
  position: absolute;
  left: 25%;
  bottom: 10%;
}

.zoom-button {
  transition: transform 0.3s ease;
}

.zoom-button:hover {
  transform: scale(1.1);
}

.url-input {
  min-height: 30px !important;
  font-size: 14px !important;
}
.upload-fc:hover{
  background: #f2f2f2;
  border-radius: 8px;
}
/* .arya-bots */
.arya-bg{
  background-color: #f0f0f0;
}
.height-fix{
  height: 100%;
}
.search-box{
  width: 70%;
  height: 50px;
}
.bottom-section{
  background-color: #f8f8f8;
  width: 90%;
}
.logo-bots{
  height: 60px;
}
.logo-ring{
  width: 90px;
  height: 90px;
}
.logo-ring-inner{
  width: 80px;
  height: 80px;
}
.ai-enabled{
  height: 40px;
}
.nav-tabs .nav-link {
  display: flex;
  align-items: center;
  color: #000;
}
.nav-tabs .nav-link:hover {
  color: #000;
}
.nav-tabs .nav-link.active {
  background-color: transparent !important;
}
.nav-link:focus, .nav-link:hover{
  color: #000 !important;
}
.form-control:focus{
  box-shadow: none !important;
}
.desc-width{
  width: 40%;
}

/* Leads Gen Start */

.bg-leads-blue{
  background-color: #007CC2 !important;
}
.text-leads-blue{
  color: #007CC2;
}
.border-leads-blue{
  border: 2px solid #007CC2;
}
.bg-leads-red{
  background-color: #E64C4C;
}
.dropdown-toggle::after{
  content: none !important;
}
.drafts-icon{
  width: 20px;
}
.rdt_TableHeadRow{
  background-color: #E8E8F6 !important;
  color: #666 !important;
  font-weight: 600 !important;
}

.selectDropDown{
  height: 42px;
  font-size: 16px !important;
}

.ButtonDesign{
  background-color: #007CC2 !important;
}
.dragdrop{
  border: 2px dashed #aaa;
  height: 200px;
}
.date-picker{
  width: 150px !important;
}
.filterdivalign{
  top: 50px;
  right: 0px;
  width: 350px;
  /* height: 420px; */
  z-index: 4;
}
.form-control:focus{
border-color: #007CC2 !important;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Fade effect with semi-transparent overlay */
  z-index: 1040; /* Ensure it appears behind the modal but above other content */
  backdrop-filter: blur(5px); /* Apply blur effect */
}
